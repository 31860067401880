h1 span,
.btn.btn-default,
.navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand,
.navbar-default .navbar-nav > .active > a, 
.navbar-default .navbar-nav > .active > a:focus, 
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li.modal-button.active > a:hover,
.control:hover,.doctor-list .overlay ul li a,
#filter-list li.active, #filter-list li.active:focus, 
#filter-list li.active:hover,
#filter-list li:hover,
.portfolio-overlay .fa:hover,
.post-thumb .post-date,
.post-info h3 a:hover,
ul.entry-meta li a:hover,
a:hover,
.navigation.post-navigation  a,
.post-by a:hover,
.post-comments a:hover,
.post-meta li a:hover,
.list-inline li a:hover,
a.more-link,
.author-post .author-content a:hover,
.single-post-tags li:first-of-type,
.single .meta-featured-post,
.sidebar .widget ul li i,
.comment-author-link,
.sidebar .widget ul li a:hover,
.contact-list a:hover,
.close:hover,
input[type="submit"].btn.btn-primary:hover,
.blog-post:hover .post-thumb .post-date a,
.navbar-default .navbar-nav > li.modal-button > a:hover,
.navbar-default .navbar-nav > li.consultation > a:hover,
.chosen-container .chosen-results li.active-result.highlighted{
	color:#18afd3;
}

.btn.btn-primary,
.btn.btn-default:hover,
.navbar-default .navbar-nav > li.modal-button > a,
.navbar-default .navbar-nav > li.modal-button.active > a, 
.navbar-default .navbar-nav > li.modal-button.active > a:focus,
.navbar-default .navbar-nav > li > a::before,
.cd-headline.clip .cd-words-wrapper::after,
.section-title h1::before,
.single-progressbar .progress .progress-bar,
.service-list:hover .service-info,
.doctor-list .thumb::before,
section.parallax-bg::before,
.portfolio_items li .post_thumb::before,
.navigation.post-navigation  a:hover,
.post-password-form input[type="submit"],
.pagination .page-numbers li span.current,
.pagination.custom .page-numbers.current,
.pagination .page-numbers li a:hover,
.pagination .page-numbers a:hover,
.pagination .page-numbers li.active,
.sidebar .widget-title::before,
.sidebar .widget .tagcloud a:hover,
.search-form form .input-group-addon,
.back-top i,
.social-media > li .fa:hover,
#consultation .modal-dialog .modal-content .modal-header,
.navbar-default .navbar-toggle .icon-bar,
.navbar-default .navbar-toggle:focus, 
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-nav > li.modal-button.active > a,
.contact-list:hover .fa,
#color-switcher h2 a,
.navbar-default .navbar-nav > li.consultation > a,
.navbar-default .navbar-nav > li.consultation > a:focus{
	background:#18afd3;
} 

.btn.btn-default,
.btn.btn-default:hover,
.navbar-default .navbar-nav > li.modal-button > a,
.single-progressbar .progress,
.service-list:hover .service-info,
.doctor-list:hover .thumb,
#filter-list li.active, 
#filter-list li.active:focus, 
#filter-list li.active:hover,
#filter-list li:hover,
.blog-post:hover,
blockquote,
.navigation.post-navigation  a,
.post-password-form input:focus,
.blog-post blockquote,
.author-post .author-img,
.sidebar .widget .tagcloud a:hover,
ol.comments li div.vcard img.photo,
.contact-list:hover .fa,
.social-media > li .fa:hover,
.modal .form-control:focus,
input[type="submit"].btn.btn-primary:hover,
.navbar-default .navbar-toggle,
.form-control:focus,
.navbar-default .navbar-nav > li.consultation > a:hover,
.navbar-default .navbar-nav > li.consultation > a:focus:hover,
.chosen-container-active.chosen-with-drop .chosen-single,
.chosen-container .chosen-drop{
	border-color:#18afd3;
}
.blog-post blockquote{
	border-left-color:#18afd3;
}
.navbar-default .navbar-nav > li.modal-button.active > a{
	background:#18afd3;!important
}
.navbar-default .navbar-nav > li.modal-button.active > a:hover{
	color:#18afd3;!important
}