/* Color Switcher */
#color-switcher {
	background:#333;
	border-radius: 0 0 3px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	left: -255px;
	position: fixed;
	top: 110px;
	width: 255px;
	z-index: 9999;
}
#color-switcher div {
	padding: 0px 10px;
}
#color-switcher h2 {
	color: #fff;
	font-size: 16px;
	margin-bottom: 0;
	margin-right: 0;
	margin-top: 0;
	padding: 10px 0;
	text-align: center;
	text-transform: uppercase;
}
#color-switcher h2 a {
	background: #18afd3;
	-webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
	-ms-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
	display: block;
	height: 50px;
	position: absolute;
	right: -50px;
	top: 0;
	width: 50px;
	-webkit-border-radius:0 5px 5px 0;
	-moz-border-radius:0 5px 5px 0;
	border-radius:0 5px 5px 0;
}
#color-switcher h2 a .fa {
	color: #fff;
	font-size: 26px;
	left: 12px;
	position: absolute;
	top: 12px;
}
.colors {
	padding-left: 7px;
}
.colors {
	list-style: none;
	margin: 0px 0px 10px 0px;
	overflow: hidden;
}
.colors li {
	float: left;
}
.colors li a {
	border: 3px solid transparent;
	-webkit-border-radius:5px;
	-moz-border-radius:5px;
	border-radius:5px;
	cursor: pointer;
	display: block;
	height: 40px;
	margin: 8px;
	width: 40px;
}
.layout-style select {
	width: 100%;
	padding: 5px;
	border: none;
	margin: 0 0 0 -5px;
	color: #fff;
	cursor: pointer;
}
#reset {
	margin: 0 0 15px 2px;
}
#reset a {
	color: #fff;
	font-size: 12px;
}
.color1 {
	background: #18afd3;
}
.color2 {
    background: #e34b11;
}
.color3 {
	background: #ff3366;
}
.color4 {
    background: #96cd02;
}
.color5 {
    background: #f50079;
}
.color6 {
    background: #ff5f83;
}
.color7 {
    background: #13bfb1;
}
.color8 {
    background: #2a80b9;
}
.color9 {
    background: #f39c11;
}
.color10 {
    background: #8f44ad;
}

.color11 {
    background: #e5c51a;
}

.color12 {
    background: #27ae62;
}
.colors li a.active {
    border-color:#fff;
}